import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';

import styles from './styles.module.scss';

import ButtonWithDropdown from '@components/_shared/buttonWithDropdown';
import { useSession } from '@utils/context/session';
import { BaseComponentProps } from '@utils/types/baseComponents';
import {
  SegmentEventNamesEnum,
  SegmentEventPagesEnum,
  useAnalytics,
} from '@utils/hooks/useAnalytics';
import { colorsByTheme } from '@utils/constants/colors';
import { Contacts } from '@utils/constants/global';
import Logo from '@components/layout/logo';
import BurgerMenu from '@components/layout/burgerMenu';
import { Envelope, Cellphone } from '@assets/icons';
import { profileDropdownLinks } from '@components/layout/constants';

export type HeaderProps = {
  browseRentalsLinks: JSX.Element;
  noGlassEffect?: boolean;
} & BaseComponentProps;

const Header = (props: HeaderProps) => {
  const {
    browseRentalsLinks,
    theme = 'dark',
    containerStyle,
    noGlassEffect = false,
  } = props;
  const { user, logOut } = useSession();
  const { trackEvent } = useAnalytics();
  const [showMobile, setShowMobile] = useState(false);
  const [showInbox, setShowInbox] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const addScrollListenerToWindow = () => {
    if (window)
      window.addEventListener('scroll', () =>
        setIsScrolled(window.scrollY > 0)
      );
  };

  useEffect(() => {
    addScrollListenerToWindow();
  }, []);

  const handleBurgerMenu = () => setIsBurgerMenuOpen((prevState) => !prevState);

  const onLoginClick = () => {
    trackEvent(SegmentEventNamesEnum.LOGIN_BUTTON_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  const onSignUpClick = () => {
    trackEvent(SegmentEventNamesEnum.SIGNUP_BUTTON_CLICK, {
      PageName: SegmentEventPagesEnum.HOME_PAGE,
    });
  };

  const onCellphoneClick = () => {
    if (showInbox) setShowInbox(false);
    setShowMobile(true);
  };

  const onInboxClick = () => {
    if (showMobile) setShowMobile(false);
    setShowInbox(true);
  };

  const profileDropdown = useMemo(
    () => (
      <>
        {user?.is_owner && (
          <Link href="/owners-portal">
            <a className={styles.buttonContainer}>Owner&apos;s Portal</a>
          </Link>
        )}

        {profileDropdownLinks.map((link) => (
          <Link href={link.href} key={link.href}>
            <a className={styles.buttonContainer}>{link.name}</a>
          </Link>
        ))}

        <span onClick={logOut}>Sign Out</span>
      </>
    ),
    [user?.is_owner]
  );

  return (
    <header
      className={clsx([
        styles.header,
        styles[theme],
        isScrolled && !isBurgerMenuOpen && styles.isScrolled,
        noGlassEffect && styles.noGlassEffect,
        containerStyle,
      ])}
    >
      <Link href="/">
        <a className={styles.header__block}>
          <Logo theme={theme} />
        </a>
      </Link>

      <section
        className={clsx([
          styles.header__capsule,
          styles[theme],
          (showMobile || showInbox) && styles.withPhoneNumberOrInbox,
          isScrolled && styles.isScrolled,
        ])}
      >
        <ButtonWithDropdown
          theme={theme}
          dropdownContent={browseRentalsLinks}
          dropdownStyle={styles.header__dropdown}
        >
          Browse Rentals
        </ButtonWithDropdown>
        <Link href="/list-on-rove">
          <a>Become an Owner</a>
        </Link>
        <Link href="/blog">
          <a>Blog</a>
        </Link>

        <div className={styles.header__contactIcons}>
          {showMobile ? (
            <a
              href={`tel:${Contacts.CALL_US}`}
              className={clsx([styles.header__phoneNumber, 'fade-animation'])}
            >
              {Contacts.CALL_US}
            </a>
          ) : (
            <div
              className={styles.header__contactIcon}
              onClick={onCellphoneClick}
            >
              <Cellphone fill={colorsByTheme[theme].textPrimaryColor} />
            </div>
          )}

          {showInbox ? (
            <a
              href={`mailto:${Contacts.CONTACT_EMAIL}`}
              className={clsx([styles.header__phoneNumber, 'fade-animation'])}
            >
              {Contacts.CONTACT_EMAIL}
            </a>
          ) : (
            <div className={styles.header__contactIcon} onClick={onInboxClick}>
              <Envelope fill={colorsByTheme[theme].textPrimaryColor} />
            </div>
          )}
        </div>
      </section>

      <div className={clsx([styles.header__block, styles.userBlock])}>
        {user ? (
          <ButtonWithDropdown
            theme={theme}
            dropdownContent={profileDropdown}
            containerStyle={styles.header__hiUser}
            dropdownStyle={clsx([styles.header__dropdown, styles.userDropdown])}
            withArrow={false}
          >
            <span>Hi, {user.first_name}</span>
          </ButtonWithDropdown>
        ) : (
          <>
            <Link href="/sign-up">
              <a
                onClick={onSignUpClick}
                className={clsx([theme === 'dark' && styles.header__singUp])}
              >
                Sign Up
              </a>
            </Link>
            <Link href="/login">
              <a onClick={onLoginClick}>Login</a>
            </Link>
          </>
        )}
      </div>

      <div
        className={styles.header__burgerIcon}
        role="button"
        onClick={handleBurgerMenu}
      >
        <div />
        <div />
      </div>

      {isBurgerMenuOpen && (
        <BurgerMenu
          browseRentalsDropdown={browseRentalsLinks}
          profileDropdown={profileDropdown}
          onClose={handleBurgerMenu}
        />
      )}
    </header>
  );
};

export default Header;
